function get_base_url(host) {
    const suffix = ".wt.askherefirst.net";
    if (host.endsWith(suffix)) {
        const app = host.substring(0, host.length - suffix.length)
        return "https://" + app + ".askherefirst.net/" + app;
    }
    return "https://onebox-onebox.azurewebsites.net";
}

export const BASE_URL = get_base_url(window.location.host);
export const SUGGESTIONS = BASE_URL + "/suggestions";
export const SEARCH = BASE_URL + "/json";
export const ISCORRECT = BASE_URL + "/isCorrect";
export const CORRECTION = BASE_URL + "/correction";
export const MODE = "Production";
