import React, { useState, useEffect } from "react";
import "./App.css";
import "./Containers/HomePage/style.scss";
import { Route, Redirect } from "react-router-dom";
import Results from "./Containers/Results";
import Header from "./Components/Header";
import Search from "./Components/Search";
import { useHistory } from "react-router-dom";
import {
  HOME_PAGE_ROUTE,
  RESULTS_PAGE_ROUTE,
  SPEECH_CHECK_MIC_TEXT,
  DEFAULT_SPEECH_SORRY_TEXT,
  SPEECHEND_TIME,
  DEFAULT_SPEECH_PLACEHOLDER,
  DEFAULT_SPEECH_LISTENING_TEXT,
  INDEX_PAGE_ROUTE,
  DESKTOP_VIEW_BREAKPOINT,
} from "./Utils/Constants";
import Speech from "./Components/Speech";
import { updateSelectedSuggestion } from "./actions";
import { useDispatch } from "react-redux";
import { compose } from "redux";

function App(props) {
  const [browserSupport, setBrowserSupport] = useState(true);
  const [activeVoice, setactiveVoice] = useState(false);
  const [recognition, setRecognition] = useState();
  const [micError, setMicError] = useState(false);
  const [recFlag, setRecFlag] = useState(false);
  const [defaultMicError, setdefaultMicError] = useState(false);
  const [listening, setListening] = useState(null);
  const [isMobile, setIsMobile] = useState(0);

  const dispatch = useDispatch();
  const history = useHistory();
  const resizeWindow = () => {
    window.innerWidth < DESKTOP_VIEW_BREAKPOINT
      ? setIsMobile(true)
      : setIsMobile(false);
  };
  useEffect(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      setRecognition(recognition);
        setBrowserSupport(true)
      } else {
       setBrowserSupport(false)
    }
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  const resultAfterPause = async (response) => {
    voiceStop();
    dispatch(updateSelectedSuggestion(response));
    if (response) {
      history.push(`${RESULTS_PAGE_ROUTE}?search=${response || ""}`);
    }
  };
  const handleSearchClick = () => {
    if (!defaultMicError) {
      setactiveVoice((prevState) => !prevState);
    }
    if (recognition) {
      recognition.stop();
      return recognition;
    }
  };
  const voiceStop = () => {
    if (recFlag) {
      setactiveVoice(true);
      if (recognition) {
        recognition.start();
      }
      setRecFlag(false);
    } else {
      setactiveVoice(false);
      if (recognition) {
        recognition.stop();
      }
      setRecFlag(false);
    }
    setMicError(false);
  };
  let noSpeechRecognation = (value) => {
    setdefaultMicError(value);
  };
  let voiceRecUpdate = (val) => {
    setRecFlag(val);
  };

  let onCloseVoice = () => {
    setactiveVoice(false);
    if (recognition) {
      recognition.stop();
    }
    setMicError(false);
  };
  let onCloseSpeech = () => {
    setactiveVoice(false);
    if (recognition) {
      recognition.stop();
    }
  };

  const onMicClick = () => {
    var recognition = handleSearchClick();
    let final_span = document.getElementById("final_span");
    let interim_span = document.getElementById("interim_span");
    let default_place = document.getElementById("default_place");

    let final_transcript = "";
    final_span.innerHTML = "";
    interim_span.innerHTML = "";

    const start = () => {
      recognition.start();
      setListening((prevState) => !prevState);
      setTimeout(() => {
        if (final_span.innerHTML || interim_span.innerHTML) {
          voiceRecUpdate(false);
          recognition.stop();
          resultAfterPause(final_span.innerHTML || interim_span.innerHTML);
        } else {
          recognition.stop();
          noSpeechRecognation(true);
          // voiceRecUpdate(true);
          if (default_place.innerHTML !== SPEECH_CHECK_MIC_TEXT) {
            default_place.innerHTML = DEFAULT_SPEECH_SORRY_TEXT;
          }
        }
      }, SPEECHEND_TIME);
    };

    recognition.onstart = (event) => {
      noSpeechRecognation(false);
      default_place.innerHTML = DEFAULT_SPEECH_PLACEHOLDER;
      final_span.innerHTML = "";
      interim_span.innerHTML = "";
      setTimeout(() => {
        default_place.innerHTML = DEFAULT_SPEECH_LISTENING_TEXT;
      }, 300);
    };
    const stop = async () => {
      recognition.stop();
    };
    var first_char = /\S/;
    function capitalize(s) {
      return s.replace(first_char, function (m) {
        return m.toUpperCase();
      });
    }
    var two_line = /\n\n/g;
    var one_line = /\n/g;
    function linebreak(s) {
      return s.replace(two_line, "<p></p>").replace(one_line, "<br>");
    }
    const onResult = (event) => {
      var interim_transcript = "";
      for (var i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          if (isMobile) {
            final_transcript = event.results[i][0].transcript;
          } else {
            final_transcript += event.results[i][0].transcript;
          }
        } else {
          if (isMobile) {
            interim_transcript = event.results[i][0].transcript;
          } else {
            interim_transcript += event.results[i][0].transcript;
          }
        }
      }
      final_transcript = capitalize(final_transcript);
      default_place.innerHTML = "";
      final_span.innerHTML = linebreak(final_transcript);
      interim_span.innerHTML = linebreak(interim_transcript);
    };
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.maxAlternatives = 1;
    recognition.addEventListener("result", onResult);

    if (!listening) {
      final_span.innerHTML = "";
      setTimeout(() => {
        start();
      }, 500);
    } else {
      stop();
    }
    setListening((prevState) => !prevState);

    recognition.onerror = function (event) {
      if (event.error === "no-speech") {
      } else {
        default_place.innerHTML = SPEECH_CHECK_MIC_TEXT;
        setMicError(true);
      }
    };
  };
  return (<>
    <div className="App">
      <Header
        isMobile={isMobile}
        activeVoice={activeVoice}
        voiceStop={voiceStop}
        voiceRecUpdate={voiceRecUpdate}
      />
      <Route
        exact
        path={INDEX_PAGE_ROUTE}
        render={() => {
          return <Redirect to={HOME_PAGE_ROUTE} />;
        }}
      />
    <Search isMobile={isMobile} browserSupport = {browserSupport} onMicClick={onMicClick} activeVoice={activeVoice}/>
      <Route exact path={RESULTS_PAGE_ROUTE}>
        <Results isMobile={isMobile} activeVoice={activeVoice} />
      </Route>
      <Speech
        activeVoice={activeVoice}
        onMicClick={onMicClick}
        micError={micError}
        defaultMicError={defaultMicError}
        onCloseVoice={onCloseVoice}
        onCloseSpeech={onCloseSpeech}
        recognition={recognition}
        noSpeechRecognation={noSpeechRecognation}
        voiceRecUpdate={voiceRecUpdate}
      />
    </div>
    <div id="query_result_container"></div>
  </>);
}

export default App;