import React, { Fragment, Component } from 'react'
import Editor from 'react-simple-code-editor'
import Highlight, { defaultProps } from 'prism-react-renderer'
import "./index.scss";
import { LANGUAGE_TOKEN } from '../../Utils/Constants';

const styles = {
  root: {
    boxSizing: 'border-box',
    border: "1px solid #BDBDBD",
    fontFamily: "Poppins",
    fontstyle: "normal",
    fontweight: 700,
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "left",
    borderRadius:"8px",
    minHeight: "176px"
  }
}

class EditorSection extends Component {

    constructor(props){
        super(props);
        this.state= {
            code:this.props.value
        }
    }

  onValueChange = code => {
    this.setState({ code });
    this.props.handleEditing(code);
  }

  highlight = code => (
    <Highlight {...defaultProps} code={code} language={LANGUAGE_TOKEN}>
      {({tokens, getLineProps, getTokenProps }) => (
        <Fragment>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => <span {...getTokenProps({ token, key })} />)}
            </div>
          ))}
        </Fragment>
      )}
    </Highlight>
  )

  render() {
    let {isMobile} = this.props;
    styles.root.minHeight = isMobile ? styles.root.minHeight : "auto"
    return (<div className="editor-wrapper">
        <Editor
        value={this.state.code}
        onValueChange={this.onValueChange}
        highlight={this.highlight}
        padding={20}
        textareaClassName={"textarea-wrapper"}
        style={styles.root}
      />
    </div>
     
    )
  }
}
export default EditorSection
