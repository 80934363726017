import Highlight, { defaultProps } from 'prism-react-renderer';
import "./style.scss";
import { Fragment } from 'react';
import { LANGUAGE_TOKEN } from '../../Utils/Constants';
const SyntaxHighLighter = (props) => {
    let {queryResult="" } = props;
  return (<div className="syntax-content-wrapper">
    <Highlight {...defaultProps} code={queryResult} language={LANGUAGE_TOKEN}>
      {({tokens, getLineProps, getTokenProps }) => (
        <Fragment>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => <span {...getTokenProps({ token, key })} />)}
            </div>
          ))}
        </Fragment>
      )}
    </Highlight>
  </div>
    
  );
};

export default SyntaxHighLighter;