import { CORRECTION, ISCORRECT, SEARCH, SUGGESTIONS } from "../Utils/api";
import { VALIDATION_LOADING_TIMEOUT } from "../Utils/Constants";
import * as types from "../Utils/types";

export async function getSearchSuggestion({data}) {
  return await fetch(`${SUGGESTIONS}?query_string=${data}`).then((response) => {
    return response.json();
  });
}

export function updateSelectedSuggestion(searchText){
    return {
      type: types.UPDATE_SELECTED_SUGGESTION,
      data: searchText,
    }
}
export const updateLoadingStatus = (isLoading) => {
  return {
    type: types.UPDATE_LOADING_STATUS,
    loadingInProgress: isLoading
  }
}
export const postIsCorrect = (data) => {
    return fetch(`${ISCORRECT}?query_string=${data.query}&feedbacktext=${data.feedbacktext}&tabname="ToReview",`, {
            timeout: VALIDATION_LOADING_TIMEOUT
          }).then((res)=>{
            return res;
          })
          .catch((error)=>{
              console.log(error,"error");
              return error;
          })
}
export const postCorrectionData = (data) =>{
  // console.log(data,"data");
    return fetch(`${CORRECTION}?query_string=${data.query}&feedbacktext=${data.feedbacktext}&tabname="ToReview",`, {
          timeout: VALIDATION_LOADING_TIMEOUT
        }).then((res)=>{
            return res;
        })
        .catch((error)=>{
            console.log(error,"error");
            return error;
        })
}

export const getSearchresults = (searchValue)  => {
    return async (dispatch) => {
      let responsedata = await fetch(`${SEARCH}?query_string=${searchValue}`).then(
          (response) => {
            return response.json();
          }
        );
      let res = await responsedata;
      // console.log("search results: ", res);
      dispatch({
        type: types.SEARCH_QUERY, 
        data: res, 
        loadingInProgress: false 
      });
    }
};

export const updateIsCorrectResponse = (res) => {
  return ({
    type: types.UPDATE_ISCORRECT_RES,
    data: res
  })
}

export const updateSearchFocus = (focused=false) => {
  return ({
    type: types.UPDATE_SEARCH_FOCUS,
    data: focused
  })
}

export const updateCurrentTheme = (theme="light") => {
  return ({
    type: types.UPDATE_THEME,
    data: theme
  })
}