
import React, { useState } from 'react';
import { Button } from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import "./style.scss";
import {COPIED, COPY_TO_CLIPBOARD} from "../../Utils/Constants";
const CopyToClipBoard = (props)=> {
    let [showCopied, setShowCopied] = useState(false);
    const handleOnClick = () =>{
      if(showCopied === true){
        return;
      }
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false)
      }, 1500); 
    }
    return (
      <div className="copy-to-clip-board-wrapper">
        {showCopied ? <div className="copied-popup"><img src="./images/check-mark-green.svg" />{COPIED}</div> : ""}
        <CopyToClipboard text={window.location.href}>
          <Button onClick={handleOnClick}>{COPY_TO_CLIPBOARD}</Button>
        </CopyToClipboard>
      </div>
    );
  
}

export default CopyToClipBoard;

