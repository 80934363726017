export const TITLE = "Ask Here First";
export const COPIED = "Copied";
export const SUBMITTED_SUCCESSFULLY = "Validation submitted successfully!";
export const LABEL_try_asking = "Try Asking..."
export const LABEL_search_initial_placeholder= "Search"
export const LABEL_default_placeholder= "What are you searching for?"
export const POSITIVE_FEEDBACK_LABEL = "Thank you for your feedback. ";
export const DISMISS = "Dismiss";
export const NEGATIVE_FEEDBACK_LABEL = "Sorry about that. Would you like to tell us what went wrong?";
export const SUCCESS = "Success";
export const LABEL_noResults_text = "Sorry, no results found ";



//Speech and Search
export const SPEECHEND_TIME = 8000;
export const STATS_SEARCHING_FOR = "What stats are you searching for?"
export const DEFAULT_SPEECH_PLACEHOLDER = "Go ahead, ask your question...";
export const DEFAULT_SPEECH_LISTENING_TEXT = "We're listening...";
export const DEFAULT_SPEECH_SORRY_TEXT = "Sorry we didn't get that. Please try again";
export const SPEECH_CHECK_MIC_TEXT = "Please check your mic and audio.";
export const DESKTOP_VIEW_BREAKPOINT = 1024;
export const NO_ROWS = 30;
export const NO_TO_SEND = 15;
export const RESULTS_PAGE_ROUTE = "/result";
export const HOME_PAGE_ROUTE = "/home";
export const INDEX_PAGE_ROUTE = "/";
export const MAX_LENGTH_OF_QUERIES_IN_LOCAL = 20;
export const NO_OF_LOCAL_QURIES = 5;
export const WHAT_STATS_FOR_MOBILE = "What are you searching for?";
export const LOCALSTORAGE_RECORDS_EXPITY_TIME = 48
export const LANGUAGE_TOKEN = "html";
export const COPY_TO_CLIPBOARD = "Copy To Clipboard";

//Feedback
export const LABEL_VALIDATE = "Validate";
export const LABEL_SUBMIT = "Submit";
export const LABEL_CLOSE = "Close";
export const FEEDBACK_ERROR_MSG = "Something went wrong. Please try again."
export const VALIDATE_DISLIKE_POPUP_HEADER = "Does this look Correct?";
export const VALIDATE_POPUP_HEADER = "Validate answer";
export const VALIDATE_DISLIKE_POPUP_SUBTEXT = "Verify and add relavant response";
export const VALIDATE_POPUP_SUBTEXT = "Does this answer look correct?";
export const LABEL_SUBMIT_CORRECT_ANSWER = "Submit the Correct Answer";
export const VALIDATION_LOADING_TIMEOUT = 12000;

