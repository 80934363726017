import { useState } from "react";
import "./index.scss";
import { Chevron, ShortCutIcon, DotsSvg } from "../SvgComponents";
import { Collapse } from "antd";

const { Panel } = Collapse;

function TermsAndConditions() {
  const [active, setActive] = useState(0);
  let handleClick = (e, { index }) => {
    const newIndex = active === index ? -1 : index;
    setActive(newIndex);
  };
  return (
    <div className="speech_error_text">
      <div className="accordion_wrapper">
        <div className="accordionDesk">
          <Collapse accordion>
            <Panel
              key="1"
              onClick={handleClick}
              expandIcon={({ isActive }) => (
                <Chevron
                  className={
                    isActive ? "accordion_icon_active" : "accordion_icon"
                  }
                />
              )}
              className="accordion_title"
              header="Change a site's microphone permissions"
            >
              <p className="accordion_content">
                <ol className="accordion_ol">
                  <li>Open your browser.</li>
                  <li>
                    At the top right, click {<DotsSvg />}{" "}
                    <strong className="accordion_content_strong">
                      Settings
                    </strong>
                    .
                  </li>
                  <li>
                    Under "Privacy and security," click{" "}
                    <strong className="accordion_content_strong">
                      Site settings
                    </strong>
                    .
                  </li>
                  <li>
                    Click{" "}
                    <strong className="accordion_content_strong">Camera</strong>{" "}
                    or{" "}
                    <strong className="accordion_content_strong">
                      Microphone
                    </strong>
                    .<li>Turn on or off Ask before accessing.</li>
                    <li>Review your blocked and allowed sites.</li>
                    <li>
                      To remove an existing exception or permission: To the
                      right of the site, click Delete.
                    </li>
                    <li>
                      To allow a site that you already blocked : Under
                      "Blocked," select the site's name and change the camera or
                      microphone permission to "Allow."
                    </li>
                  </li>
                </ol>
              </p>
            </Panel>
          </Collapse>
          <Collapse accordion className="accordion_to_hide">
            <Panel
              key="2"
              onClick={handleClick}
              className="accordion_title"
              header="Fix problems with your mic"
			  expandIcon={({ isActive }) => (
                <Chevron
                  className={
                    isActive ? "accordion_icon_active" : "accordion_icon"
                  }
                />
              )}
            >
              {/* <u className="underline_title">Fix problems with your mic</u> */}
              <p className="accordion_content">
                <div>If your microphone isn't working, try these steps:</div>
                <div className="accordion_item_steps">
                  <div>
                    <h1>Step 1:</h1>Make sure that you aren't muted
                  </div>
                  <div>
                    If you're using a headset. make sure that the switch on the
                    cable is off. Also, make sure that you
                  </div>
                  <div>aren't muted on the website</div>
                </div>
                <div className="accordion_item_steps">
                  <div>
                    <h1>Step 2:</h1> Check your system settings
                  </div>
                  <div>
                    Make sure that your microphone is the default recording
                    device, and that recording levels are correct:
                  </div>
                  <div>
                    <div className="deviceSpecific">
                      -{" "}
                      <a
                        href="https://support.microsoft.com/help/4027981/windows-how-to-set-up-and-test-microphones-in-windows-10"
                        target="_blank"
                        rel="noopener"
                      >
                        <strong className="accordion_content_strong">
                          <u>Windows</u>
                        </strong>
                        <ShortCutIcon className="ShortCutIcon" />
                      </a>
                    </div>
                    <div className="deviceSpecific">
                      -{" "}
                      <a
                        href="https://support.apple.com/kb/PH22070"
                        target="_blank"
                        rel="noopener"
                      >
                        <strong className="accordion_content_strong">
                          <u>Mac</u>
                        </strong>
                        <ShortCutIcon className="ShortCutIcon" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="accordion_item_steps">
                  <div>
                    <h1>Step 3:</h1> Contact your manufacturer
                  </div>
                  <div>
                    if you're still having problems. contact your microphone's
                    manufacturer.
                  </div>
                </div>
              </p>
            </Panel>
          </Collapse>
        </div>
        <div className="accordionMobile">
				<div>
					<span>Make sure the application has permission to access the microphone on your device</span>
					<span>If your microphone isn't working,try these steps: </span>
				</div>
				<div className = "mobile_steps">
					<div>
						<span>Step 1: Make sure that you aren't muted.</span>
						<span>If you're using headset, make sure that the mute switch on the cable is off.</span>
					</div>
					<div>
						<span>Step 2: Check your system settings.</span>
						<span>Make sure that your microphone is the default recording device.</span>
					</div>
					<div>
						<span>Step 3: Contact your manufacturer.</span>
						<span>If you're stillhaving problems. contact your microphone's manufacturer</span>
					</div>
				</div>
			</div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
