import React, { useState } from 'react';
import { Modal , Button} from 'antd';
import "./style.scss";
const ModalWrapper = (props) => {
  const handleOk = () => {
    // props.closeModal()
  };

  const handleCancel = () => {
    props.closeModal()

  };
let {content,isModalVisible=false,title} = props;
  return (
    <>
      <Modal title={title} visible={isModalVisible} centered={true} footer={[<Button key="close" onClick={handleCancel}>
Close
</Button>]}>
        {content}
      </Modal>
    </>
  );
};
export default ModalWrapper;