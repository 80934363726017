export const UPDATE_TRY_ASKING_SUGGESTIONS = "UPDATE_TRY_ASKING_SUGGESTIONS";
export const UPDATE_SELECTED_SUGGESTION = "UPDATE_SELECTED_SUGGESTION";
export const UPDATE_CURRENT_VIEW = "UPDATE_CURRENT_VIEW";
export const UPDATE_SEARCH_VALUE = "UPDATE_SEARCH_VALUE";
export const UPDATE_SEARCH_CLEAR = "UPDATE_SEARCH_CLEAR";
export const POST_ISCORRECTION = "POST_ISCORRECTION";
export const POST_CORRECTION_DATA = "POST_CORRECTION_DATA";
export const SEARCH_QUERY = "SEARCH_QUERY";
export const UPDATE_ISCORRECT_RES = "UPDATE_ISCORRECT_RES";
export const UPDATE_SEARCH_FOCUS = "UPDATE_SEARCH_FOCUS";
export const UPDATE_LOADING_STATUS  = "UPDATE_LOADING_STATUS";
export const UPDATE_THEME = "UPDATE_THEME";