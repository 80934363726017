import React from "react";
import Autosuggest from "react-autosuggest";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import ContentEditable from "react-contenteditable";
import { withRouter } from "react-router-dom";
import {
  getSearchresults,
  getSearchSuggestion,
  updateLoadingStatus,
  updateSearchFocus,
  updateSelectedSuggestion,
} from "../../actions";
import { connect } from "react-redux";
import { uniqBy } from "lodash";
import "./style.scss";
import {
  LABEL_default_placeholder,
  LABEL_search_initial_placeholder,
  NO_OF_LOCAL_QURIES,
  LOCALSTORAGE_RECORDS_EXPITY_TIME,
  RESULTS_PAGE_ROUTE,
  HOME_PAGE_ROUTE,
  MAX_LENGTH_OF_QUERIES_IN_LOCAL,
} from "../../Utils/Constants";
import {
  Cross,
  SuggestionsCrossIcon,
  Revert,
  ChartLineIcon,
  SearchIcon,
} from "../SvgComponents";
// import Speech from "../Speech";
import Image from "../Image";
import Theme from "../Theme";

let optionsList = [];

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      showSuggestions: false,
      doRefresh: null,
      searchFocused: false,
      isBlurAllow: true,
      redirectToResult: false,
      html: "",
    };
    this.searchRef = React.createRef();
  }

  componentDidMount() {
    let {location:{search = ""}} = this.props;
    let spiltedValue = search && search.split("=")[1];
    let decodedQuery = spiltedValue && decodeURI(spiltedValue);
    this.setState({
      doRefresh: Math.random(),
      value: decodedQuery,
      html: decodedQuery,
    });
    // this.props.dispatch(updateSelectedSuggestion(decodedQuery));
    this.props.dispatch(updateLoadingStatus(true));
    this.props.dispatch(getSearchresults(decodedQuery));
    document.addEventListener("mouseover", (a) => {
      if (a.target.classList.contains("clear_img")) {
        this.setState({
          isBlurAllow: false,
        });
        a.target.addEventListener("click", function () {
          console.log("clicked! close");
        });
      } else {
        this.setState({
          isBlurAllow: true,
        });
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    let {staticSuggestionText="", location={}} = this.props;
    if (nextProps.staticSuggestionText && staticSuggestionText != nextProps.staticSuggestionText) {
      this.StoringQuriesInLocalStorage(nextProps.staticSuggestionText);
      this.setState({
          showSuggestions: true,
          suggestions: this.getSuggestions(nextProps.staticSuggestionText),
          value: nextProps.staticSuggestionText,
          html: nextProps.staticSuggestionText,
        }, () => {
          this.handleSubmit();
        }
      );
    }
    if(location.pathname != nextProps.location.pathname){
      if(nextProps.location.pathname == HOME_PAGE_ROUTE){
        this.setState({
          placeholder: LABEL_search_initial_placeholder,
          value: "",
          searchFocused: nextProps.isMobile ? false : this.state.searchFocused,
          html: nextProps.isMobile ? "" : this.state.html,
        });
      }
    }
    let { location: { search = "" } } = nextProps;
    let spiltedValueSearch = search && search.split("=")[1];

    let { location:  { search: _search = "" } } = this.props;
    let spiltedValue_search = _search && _search.split("=")[1];
    if (spiltedValueSearch !== spiltedValue_search) {
      this.setState({ value: spiltedValueSearch, html: spiltedValueSearch });
    }
    if (this.props.clearSearch !== nextProps.clearSearch) {
      this.setState({ value: "" });
    }
  }
  StoringQuriesInLocalStorage = (query) => {
    //Setting recent searched data into local storage
    let askHereQuries = JSON.parse(localStorage.getItem("askHereQuries")) || [];
    let queryObj = {};
    if (query) {
        queryObj = {
          query: query,
          time: new Date()
        }
        if(askHereQuries.length >= MAX_LENGTH_OF_QUERIES_IN_LOCAL){
          askHereQuries.shift();
        } 
        askHereQuries = this.queryExist(askHereQuries, queryObj)
        askHereQuries.push(queryObj);
        localStorage.setItem("askHereQuries", askHereQuries && JSON.stringify(askHereQuries))
    }
  }
  queryExist = (askHereQuries, queryObj) => {
      let filteredQueries = askHereQuries.filter(obj => {
          if (obj.query != queryObj.query) {
              return obj
          }
      })
      return filteredQueries;
  }
  handleSubmit = () => {
    let {value = ""} = this.state;
    optionsList = [];
    this.setState({
      searchFocused: false,
    });
    if(value){
    this.props.history.push(`${RESULTS_PAGE_ROUTE}?search=${value || ""}`);
    this.props.dispatch(updateSearchFocus(false));
    this.props.dispatch(updateLoadingStatus(true));
    this.props.dispatch(getSearchresults(value));
    }
  };
  renderSuggestion = (suggestion, { query }) => {
    const suggestionText = `${
      typeof suggestion.name === "object"
        ? suggestion.name.query
        : suggestion.name
    }`;
    let { showSuggestions } = this.state;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);
    return showSuggestions ? (
      <span className={"suggestionContent"}>
        <span className={"name"}>
          {suggestion.key == "localQuery" ? (
            <div className={"localquery"}>
              <div className={"history_query"}>
                <Revert className={"searchIcon"} />
                <span className={"text"}>
                  {parts.map((part, index) => {
                    let className = part.highlight
                      ? "highlight"
                      : "nothighlight";
                    if (this.state.value) {
                      className = part.highlight ? "nothighlight" : "highlight";
                    }
                    return <span key={index}>{part.text}</span>;
                  })}
                </span>
              </div>
              {/* <div onClick={this.onLocalqueryClear(suggestion.name)}>
                <SuggestionsCrossIcon className={"crossIcon"} />
              </div> */}
            </div>
          ) : (
            <div className={"trending_query"}>
              {suggestion.key === "matching" ? (
                <SearchIcon className={"searchIcon"} />
              ) : (
                <ChartLineIcon className={"searchIcon"} />
              )}
              <span className={"text"}>
                {parts.map((part, index) => {
                  let className = part.highlight ? "highlight" : "nothighlight";
                  if (this.state.value) {
                    className = part.highlight ? "nothighlight" : "highlight";
                  }
                  return (
                    <span className={className} key={index}>
                      {part.text}
                    </span>
                  );
                })}
              </span>
            </div>
          )}
        </span>
      </span>
    ) : null;
  };

  escapeRegexCharacters = (str = "") => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  getSuggestions = (value = "") => {
    let new_optionsList = optionsList;
    const escapedValue = this.escapeRegexCharacters(value.trim()) || "";
    optionsList = new_optionsList.filter((suggestion) => {
      let finalRe = "";
      if (typeof suggestion.name === "object") {
        finalRe = suggestion.name.query
          .toLowerCase()
          .startsWith(escapedValue.toLowerCase());
      } else {
        finalRe = suggestion.name
          .toLowerCase()
          .startsWith(escapedValue.toLowerCase());
      }
      return finalRe;
    });
  };

  getSuggestionValue = (suggestion = {}) => {
    return `${suggestion.name}`;
  };
  // Autosuggest will call this function every time you need to update suggestions
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  suggestionFormatter = (response, localData) => {
    let suggestionlist = [];
    if (localData) {
      localData.length &&
        localData.map((res) => {
          if (res !== null) {
            let suggestions = {};
            suggestions["name"] = res.query;
            suggestions["key"] = "localQuery";
            suggestionlist.push(suggestions);
            return null;
          }
        });
    }
    response.top_autocomplete.map((res) => {
      let suggestions = {};
      suggestions["name"] = res;
      suggestions["key"] = "matching";
      suggestionlist.push(suggestions);
      return null;
    });
    response.top_suggestions.map((res) => {
      let suggestions = {};
      suggestions["name"] = res;
      suggestions["key"] = "trending";
      suggestionlist.push(suggestions);
      return null;
    });
    suggestionlist = uniqBy(suggestionlist, "name");
    return suggestionlist;
  };
  onChange = async (event, { newValue = "", method = "" }) => {
    let { value } = this.state;
    this.setState({
      showSuggestions: true,
    });
    if (!(newValue === " ")) {
      this.setState({ value: newValue, showCross: true });

      if (method === "type" || method === "down") {
        let response = await getSearchSuggestion({ data: newValue });
        if (response) {
          // to delete the localstorage records after 48 hrs
          let askHereQuries = JSON.parse(localStorage.getItem("askHereQuries"));
          let ask = askHereQuries && askHereQuries.filter((ahq) => {
              let oldDate = new Date(ahq.time);
              let diff = (new Date() - oldDate.getTime()) / 1000;  //get time in milliseconds to seconds
              diff /= 60 * 60; //get time in hours
              return (diff <= LOCALSTORAGE_RECORDS_EXPITY_TIME)   //check if the stored item is within 48 hrs
          });
          if (ask) {
            localStorage.setItem("askHereQuries", ask && JSON.stringify(ask));
          }
          // let localData = JSON.parse(localStorage.getItem("askHereQuries"));
          let new_localData = ask?.slice(0, NO_OF_LOCAL_QURIES);
          const suggestionlist = this.suggestionFormatter(
            response,
            new_localData
          );
          optionsList = suggestionlist;
          this.setState({
            suggestionlist: suggestionlist,
            suggestions: this.getSuggestions(newValue),
          });
        }
      }
    }
  };
  handleMobileOnChange = async (event) => {
    if(event.currentTarget && event.currentTarget.innerText && event.currentTarget.innerText.trim()){
      this.setState({
        showSuggestions: true,
        html: event.target.value,
      });
  
      if (event.currentTarget.innerText !== "") {
        this.setState({ value: event.currentTarget.innerText, showCross: true });
  
        let response = await getSearchSuggestion({
          data: event.currentTarget.innerText,
        });
        if (response) {
          let localData = JSON.parse(localStorage.getItem("askHereQuries"));
          let new_localData = localData?.slice(0, NO_OF_LOCAL_QURIES);
          const suggestionlist = this.suggestionFormatter(
            response,
            new_localData
          );
          optionsList = suggestionlist;
          this.setState({
            suggestions: this.getSuggestions(event.currentTarget.innerText),
          });
        }
      }
    } else {
      this.setState({html: ""});
    }
  };
  setFocusValue = (event) => {
    if (event && event.target) {
      event.target.value = "";
      event.target.value = this.state.value;
      event.target.scrollTo(1000, 0);
    }
  };

  onFocus = async (event) => {
    this.setState(
      {
        showSuggestions: true,
      },
      () => this.setFocusValue(event)
    );
    this.setState({
      searchFocused: true,
    });
    if (event.target.value !== "") {
      this.setState({
        showCross: true,
      });
    }
    if (event.target.value === "") {
      let response = await getSearchSuggestion({ data: event.target.value });
      if (response) {
        const suggestionlist = this.suggestionFormatter(response);
        optionsList = suggestionlist;
        this.setState({
          searchFocused: true,
          suggestionlist: suggestionlist,
          placeholder: LABEL_default_placeholder,
        });
      }
    }
  };

  blurInputElement = () => {
    let elem = document.getElementById("result_search_input");
    let mobileEle = document.getElementById("span_search_input");
    elem && elem.blur();
    mobileEle && mobileEle.blur();
  };

  onSuggestionSelected = (event, { suggestionValue }) => {
    this.setState(
      {
        showSuggestions: false,
        placeholder: LABEL_default_placeholder,
        html: suggestionValue,
      },
      () => {
        this.props.dispatch(updateSelectedSuggestion(suggestionValue));
        this.blurInputElement();
      }
    );
  };

  onBlur = () => {
    optionsList = [];
    this.setState({
      showSuggestions: false,
      placeholder: LABEL_search_initial_placeholder,
      searchFocused: false,
    });
    this.props.dispatch(updateSearchFocus(false));
  };
  // onLocalqueryClear = (suggestion) => {
  //   let queries = JSON.parse(localStorage.getItem("askHereQuries"));
  // };

  handlekeyDown = (event) => {
    if (event.key === "Enter" && this.state.value) {
      this.onBlur();
      this.props.dispatch(updateSelectedSuggestion(this.state.value));
      this.setState({ searchFocused: false }, () => this.blurInputElement());
    }
  };

  onBackArrowClick = () => {
    this.props.history.goBack();
  };

  renderInputComponent = (inputProps) => {
    inputProps = {
      ...inputProps,
      className: `${inputProps.className} search-bar ${
        this.state.value ? `${"value_present"} search_val` : ""
      }`,
    };
    let {isMobile} = this.props;
    return (
      <div className={`search_cmp_wrapper ${"Wrapper"}`}>
        {isMobile ? (
          <ContentEditable
            id="span_search_input"
            contentEditable={true}
            onFocus={this.handleMobileOnFocus}
            onKeyDown={this.handlekeyDown}
            onChange={(e) => this.handleMobileOnChange(e)}
            html={this.state.html}
            ref={null}
          />
        ) : (
          <input id="result_search_input" {...inputProps} />
        )}
      </div>
    );
  };
  onClear = () => {
    this.props.dispatch(updateSelectedSuggestion(""));
    this.setState({ value: "" });
  };

  handleMobileOnFocus = async (event) => {
    this.setState(
      {
        showSuggestions: true,
        searchFocused: true,
        placeholder: LABEL_default_placeholder,
      }, () => this.setFocusValue(event));
    this.props.dispatch(updateSearchFocus(true));
    let response = await getSearchSuggestion({ data: this.state.html });
    if (this.state.html !== "") {
      this.setState({
        showCross: true,
      });
    }
    if (response) {
      const suggestionlist = this.suggestionFormatter(response);
      optionsList = suggestionlist;
      this.setState({
        searchFocused: true,
      })
    }
  };

  onClosepopUp = () => {
    let isInResultPage = this.props.location.pathname == RESULTS_PAGE_ROUTE;
    this.setState({
      searchFocused: false,
      value: isInResultPage ? this.state.value : "",
      placeholder: LABEL_search_initial_placeholder,
    });
    optionsList = [];
  };
  
  render() {
    const {
      value,
      showSuggestions = false,
      searchFocused,
      placeholder = LABEL_search_initial_placeholder,
      isBlurAllow,
    } = this.state;
    let { isMobile, location } = this.props;
    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: placeholder,
      value,
      onChange: this.onChange,
      onFocus: isMobile ? this.handleMobileOnFocus : this.onFocus,
      onKeyDown: this.handlekeyDown,
      onBlur: isMobile ? "" : isBlurAllow && this.onBlur,
    };

    if (!searchFocused) {
      optionsList = [];
    }
    return (
      <>
      {this.props.activeVoice ? null : <section
        className={location.pathname == HOME_PAGE_ROUTE ? "home" : "resultspg"}
      >
        <div className={`sql-pro-search-wrapper ${searchFocused ? "wrapper-focused" : ""}`}>
          <div className={`search_bar_wrapper ${
              searchFocused ? (showSuggestions ? "container_wrapper_focused" : "") : "placeholderBlured placeholder_blur"
          }`}>
            <div className="main-search-wrapper">
              <Autosuggest
                suggestions={optionsList}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                onSuggestionSelected={this.onSuggestionSelected}
                renderInputComponent={this.renderInputComponent}
                inputProps={inputProps}
                alwaysRenderSuggestions={true}
                onKeyDown={this.handlekeyDown}
              />
              <div className={searchFocused ? (showSuggestions ? "inputIsFocused" : "") : ""}></div>
              <div className="magnifier-icon-wrapper">
                <img
                  className={"search-magnifier"}
                  src={"/images/search_icon.svg"}
                  onClick={this.handleSubmit} 
                />
              </div>
            </div>
          </div>
          {this.props.browserSupport ?<div className="voiceSearch">
            <button onClick={this.props.onMicClick}>
              <Image sourceIcon="microphone" />
            </button>
          </div>:null}
        </div>
        {(location.pathname == HOME_PAGE_ROUTE) ? <Theme isMobile={isMobile} isHome={true} /> : ""}
      </section>}
      </>
    );
  }
}

const mapStateToProps = ({ 
  staticSuggestionText,
  searchQueryDetails 
}) => ({
  staticSuggestionText,
  searchQueryDetails
});
// export default connect(mapStateToProps)(Search);
export default connect(mapStateToProps)(withRouter(Search));
