import React, { useState } from "react";
import { useSelector } from "react-redux";
import "antd/dist/antd.css";
import "./index.scss";
import { VALIDATE_DISLIKE_POPUP_HEADER, VALIDATE_DISLIKE_POPUP_SUBTEXT,
VALIDATE_POPUP_HEADER, VALIDATE_POPUP_SUBTEXT } from "../../Utils/Constants";

const Validate = (props) => {
  let [liked, setLiked] = useState(null);
	const {loadingInProgress}= useSelector((state) => {
    return({ loadingInProgress: state.loadingInProgress });
  });

  const handleSelection = (selection, type) => {
    if(loadingInProgress){
      return;
    }
    (type === "like") ? setLiked(true) : setLiked(false);
    props.handleSelection(selection, type);
  };

  const feedbackTemplate = () => {
    return(<section className="validate_container">
            <div className="validate_question">
              {(liked === false) ? <h1>{VALIDATE_DISLIKE_POPUP_HEADER}</h1> : <h1>{VALIDATE_POPUP_HEADER}</h1>}
              {(liked === false) ? <p>{VALIDATE_DISLIKE_POPUP_SUBTEXT}</p> : <p>{VALIDATE_POPUP_SUBTEXT}</p>}
            </div>
            <div className="validate_icons-container">
              <div className="validate_icons">
              <div className="icons border-right">
                <span onClick={(e) => handleSelection(false, "dislike")}>
                  <img className={`dislike-btn ${(liked===false) ? "filled_icon" : ""}`} src={(liked===false) ? "./images/disliked.svg" : "./images/dislike_default.svg"} />
                </span>
              </div>
              <div className="icons">
                <span onClick={(e) => handleSelection(true, "like")}>
                  <img className={`like-btn ${liked ? "filled_icon" : ""}`} src={liked ? "./images/liked.svg" : "./images/like_default.svg"} />
                </span>
              </div>
              </div>
            </div>
          </section>);
  }

  return (
    <div className="validate_wrapper">
        {feedbackTemplate()}
    </div>
  );
};

export default Validate;
