import React from "react";

const Image = (props) => {
    let { source = "", alt = "image", style, className = "", sourceIcon = "" } = props;
    let imgSrc;
    // loading the images/icons dynamically
    if (source) {
        imgSrc = require("../../data/assests/" + source + ".png").default;
    } else if (sourceIcon) {
        imgSrc = require("../../data/assests/" + sourceIcon + ".svg").default;
    };
    return (
        <img  onClick = {props.onClick} className={className || ""} src={imgSrc || ""} alt={alt} style={style || {}} />
    );
};
export default Image;