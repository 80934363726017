// import Search from "../Searchbar";
import {TITLE,INDEX_PAGE_ROUTE} from "../../Utils/Constants";
import "./style.scss";
import {withRouter} from "react-router-dom";
import React from "react";
import { connect } from "react-redux";
class Header extends React.Component {
	render(){
		return (<>
			<header className='header'>
				<div className='header_logo'	
					onClick={() => {
					if(this.props.loadingInProgress){
						return;
					}
					this.props.voiceRecUpdate(false);
					setTimeout(()=>{
						this.props.voiceStop();
					},100)
					
					this.props.history.push(INDEX_PAGE_ROUTE);
				}}>	<a>{TITLE}</a></div>
			</header>
		</>);
	}
}
const mapStateToProps = ({ 
	loadingInProgress
  }) => ({
	loadingInProgress
  });
export default connect(mapStateToProps)(withRouter(Header));
