import * as types from "../Utils/types";

const IntialState = {
    searchQueryDetails: [],
    postQueryDetails:[],
    theme: "light",
    loadingInProgress: false,
    isSearchFocused: false
  };
  
  const appReducer = (state = IntialState, action) => {
    switch (action.type) {
      case "SEARCH_QUERY":
        return Object.assign({}, state, {
          searchQueryDetails: action.data,
          loadingInProgress: action.loadingInProgress
        });
        case "POST_QUERY":
        return Object.assign({}, state, {
          postQueryDetails: action.payload
        });
        case types.UPDATE_SELECTED_SUGGESTION:
          return {
            ...state,
            staticSuggestionText: action.data
          };
        case types.UPDATE_ISCORRECT_RES:
          return {
            ...state,
            isCorrectAcknowledge: action.res
          };
        case types.UPDATE_SEARCH_FOCUS: 
          return {
            ...state,
            isSearchFocused: action.data
          };
        case types.UPDATE_LOADING_STATUS:
          return {
            ...state,
            loadingInProgress: action.loadingInProgress
          };
        case types.UPDATE_THEME:
          return {
            ...state,
            theme: action.data
          };
      default:
        return state;
    }
  };
  const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'CLEAR_REDUCER') {
      state = undefined;
    }
  
    return appReducer(state, action);
  };
  export default rootReducer;