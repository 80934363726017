import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import SyntaxHighLighter from "../../Components/SyntaxHighLighter";
import CopyToClipBoard from "../../Components/CopyToClipBoard";
import Theme from "../../Components/Theme";
import Validate from "../../Components/Validate";
import { Button } from "antd";
import "./style.scss";
import Editor from "../../Components/Editor";
import ModalWrapper from "../../Components/ModalWrapper";
import { SUBMITTED_SUCCESSFULLY, INDEX_PAGE_ROUTE,FEEDBACK_ERROR_MSG, LABEL_SUBMIT_CORRECT_ANSWER, LABEL_SUBMIT } from "../../Utils/Constants";
// import { postQuery } from "./actions";
import _history from "../../Utils/history";
import { useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { postIsCorrect,postCorrectionData} from "../../actions";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { MODE } from "../../Utils/api";

const iframeTranslationTable = {
    "www.wayfair.com": "proxy-wayfair.askherefirst.net",
    "www.priceline.com": "www.priceline.com",
    "www.kayak.com": "proxy-kayak.askherefirst.net",
    "www.expedia.com": "proxy-expedia.askherefirst.net",
    "www.airbnb.com": "proxy-airbnb.askherefirst.net"
};

const Results = (props) => {
	let history = useHistory();
	let [isResultCorrect, setIsResultCorrect] = useState(true);
	let [editResults, setEditedResult] = useState("");
	let [isModalVisible, setIsModalVisible] = useState(false);
	let [queryResult,setQueryResults]=useState("")
	let [successResponse,setSuccessResponse] = useState(false);
        let [submitDisable,setSubmitDisable] = useState(true);
        let resultIncludesURL = queryResult && (queryResult.includes("http") || queryResult.includes("https"));
    console.log(queryResult);
    let iframeURL = null;
    if (resultIncludesURL) {
        let url_str = (queryResult.startsWith("{") ? Object.values(JSON.parse(queryResult))[0] : queryResult);
        if (url_str.startsWith("http://") || url_str.startsWith("https://")) {
            try {
                iframeURL = new URL(url_str);
                if (iframeURL.host in iframeTranslationTable) {
                    iframeURL.hostname = iframeTranslationTable[iframeURL.host];
                } else {
                    iframeURL = null
                }
            } catch (error) {
                console.error(error)
                iframeURL = null;
            }
        }
    }

	const {query, loadingInProgress, isSearchFocused} = useSelector((state) => {
		return { 
			query: state.searchQueryDetails, 
			loadingInProgress: state.loadingInProgress,
			isSearchFocused: state.isSearchFocused
		};
	});
	const handleSelection = async (selection, type) => {
		if (type == "dislike") { 
			setIsResultCorrect(selection);
			setEditedResult(queryResult);
		} else {
			setIsResultCorrect(selection);
			let data = {
				query: query && query.query_string || "",
				feedbacktext: isResultCorrect ? "" : editResults,
			};
			setSuccessResponse("loading");
			let response = await postIsCorrect(data);
			setIsModalVisible(true);
			if (response && response.status == 200) {
				setSuccessResponse(true)
			}else{
				setSuccessResponse(false);
			}
		}
	};
	const handleEditing = (value) => {
		if(queryResult !== value && value && value.trim() ){
			setSubmitDisable(false)
			setEditedResult(value);
		}else {
			setSubmitDisable(true)
		}
	};
	const handleSubmit = async () => {
		let data = {
			query: query && query.query_string || "",
			feedbacktext: isResultCorrect ? "" : editResults,
		};
		setSuccessResponse("loading");
        let response = await postCorrectionData(data);
		setIsModalVisible(true);
		if (response && response.status == 200) {
			setSuccessResponse(true);
		}else{
			setSuccessResponse(false);
		}
	};
	const closeModal = () => {
		setIsModalVisible(false);
		props.dispatch({type:"CLEAR_REDUCER"})
		history.push(INDEX_PAGE_ROUTE);
	};
	useEffect(()=>{
		setIsResultCorrect(true);
		setQueryResults(query.query_result);
	}, [query.query_result])
	useEffect(() => {
		if(loadingInProgress || (successResponse === "loading")){
			const loaderIcon = <LoadingOutlined spin />;
			ReactDOM.render(<Spin indicator={loaderIcon} />, document.getElementById("query_result_container"));
		} else {
			ReactDOM.unmountComponentAtNode(document.getElementById("query_result_container"));
		}
	}, [loadingInProgress, successResponse]);
	return (
		<>
		{(props.activeVoice) ? null : <div className={`results-wrapper ${(isSearchFocused || loadingInProgress) ? "hide_results_wrapper" : ""}`}>
			<div className='container'>
				<div className="syntax-highlighter-wrapper">
		{loadingInProgress ? "" : ! resultIncludesURL ? (<SyntaxHighLighter queryResult={queryResult} />) : queryResult.startsWith("{") ?
		 Object.entries(JSON.parse(queryResult)).map(([key, val], idx) => (<p key={key}><a href={val} target="_blank" rel="noreferrer">{key}
										   </a></p>))  : (<a href={queryResult} target="_blank">{queryResult}</a>)
		}
				</div>
                {loadingInProgress || !iframeURL ? "" 
                 : (<iframe id="query_result_iframe" src={iframeURL.href}></iframe>)
                }
				{ MODE === "QA" ? (
					<React.Fragment>
						{loadingInProgress ? "" : <Validate isMobile={props.isMobile} handleSelection={handleSelection}/>}
						{!isResultCorrect ? (
							<div className='edit-result align-left'>
								<div className='submit-correct-answer'>
									{LABEL_SUBMIT_CORRECT_ANSWER}
								</div>
								<Editor value={editResults} handleEditing={handleEditing} />
								<Button
									type='primary'
									className = {submitDisable?"submitInactive":"submitActive"}
									aria-setsize='large'
									disabled = {submitDisable}
									onClick={handleSubmit}>
									{LABEL_SUBMIT}
								</Button>
							</div>
						) : null}
						{successResponse === "loading" ? "" : <ModalWrapper
							content={successResponse?SUBMITTED_SUCCESSFULLY:FEEDBACK_ERROR_MSG}
							closeModal={closeModal}
							isModalVisible={isModalVisible}
						/>}
					</React.Fragment>
				) : null}
			</div>

			<div className="clip_wrapper">
				<Theme />
				<div className="copy-clip-board-position">
					<CopyToClipBoard queryResult={queryResult} />
				</div>
			</div>
		</div>}
		</>
	);
};

export default connect()(Results);
