import {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentTheme } from "../../actions";
import "./index.scss";

function Theme(props) {
    const theme = useSelector((state) => state.theme);
    const dispatch = useDispatch();
    const [showDarkModeText, setShowDarkModeText] = useState(false);
    useEffect(() => {
        theme && ((theme == "light") ? setShowDarkModeText(true) : setShowDarkModeText(false));
    }, [theme]);
    useEffect(() => {
        let {classList} = document.body;
        if(classList.value==='dark'){
            setShowDarkModeText(false);
        } else {
            setShowDarkModeText(true);
        }
    }, []);
    const enableDarkMode=()=>{
        let {classList} = document.body;
        if(classList.value==='light'){
            classList.replace('light','dark');
        }else{
            classList.add('dark');
        }
        dispatch(updateCurrentTheme("dark"));
    }
    const disableDarkMode=()=>{
        let {classList} = document.body;
        if(classList.value==='dark'){
            classList.replace('dark','light');
        }else{
            classList.add('light');
        }
        dispatch(updateCurrentTheme("light"));
    }
    const handleClick=()=>{
        if(showDarkModeText === true){
            enableDarkMode();
        }else{
            disableDarkMode();
        }
    }
    return (
        (props.isHome) ? <div className="theme-modes-container">
            <div className="dark-switch" onClick={enableDarkMode}>{"Dark Mode"}</div> 
            <div  className="light-switch" onClick={disableDarkMode}>{"Light Mode"}</div>
        </div> : <div className="color_switch" onClick={handleClick}><span>{`Switch to ${showDarkModeText ? "dark" :"light"} mode`}</span></div>
    )
}

export default Theme
