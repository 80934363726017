import React,{useState,useEffect} from "react";
import "./index.scss";
import { SpeakerIcon, Cross } from "../SvgComponents";
import TermsAndConditions from "../TermsAndConditionsofSpeech";
import Image from "../Image";
import {useHistory} from "react-router-dom";
import {RESULTS_PAGE_ROUTE,SPEECHEND_TIME,DEFAULT_SPEECH_SORRY_TEXT} from "../../Utils/Constants";
import { useDispatch } from "react-redux";
import { updateSelectedSuggestion} from "../../actions";

const SpeechView = (props) => {
    let history = useHistory();
	const [recogStart,setRecogStart] = useState(null)
	let dispatch = useDispatch();

  useEffect(() => {
	  setTimeout(() => {
		  if (recogStart) {
			  resultHandle();
		  }
	  }, SPEECHEND_TIME)
  }, [recogStart]);

	let resultHandle = () => {
		let final_span = document.getElementById("final_span");
		let interim_span = document.getElementById("interim_span");
		let default_place = document.getElementById("default_place");
		if (final_span.innerHTML || interim_span.innerHTML) {
			props.onCloseSpeech();
			history.push(`${RESULTS_PAGE_ROUTE}?search=${final_span.innerHTML || interim_span.innerHTML}`);
			dispatch(updateSelectedSuggestion(final_span.innerHTML || interim_span.innerHTML))
		} else {
			setRecogStart(null)
			default_place.innerHTML = DEFAULT_SPEECH_SORRY_TEXT;
			props.recognition.stop();
			props.noSpeechRecognation(true);
			props.voiceRecUpdate(false);
		}
	}
    return(
		<>
		{props.activeVoice ?<Cross className="cross" onClick={props.onCloseVoice} />:null}
		<section className={`speech_wrapper ${props.activeVoice ? "show_wrapper" : "hide_wrapper"} ${(history.pathname === RESULTS_PAGE_ROUTE && props.activeVoice) ?"speech_mobile":""} `}>
			<div className="wrapper">
				<div
					className={`search_cmp ${
						props.activeVoice
							? "speech_component_active"
							: "speech_component"
					}`}>
					<SpeakerIcon className="speaker_icon" />
					<div className={`speech_component_input speech-input`}>
						<span id='default_place'></span>
						<span className='final' id='final_span'></span>
						<span className='interim' id='interim_span'></span>
					</div>
				</div>
				{props.micError && <TermsAndConditions />}
			</div>
			{props.activeVoice ? (
				<div
					className={props?.micError || props?.defaultMicError
							? "speech_icon_inactive"
							: "speech_icon_active"}>
						<Image sourceIcon='microphone' 
						onClick={()=>{
							if(props.micError){
								return false;
							}
							if (props.defaultMicError) {
								setRecogStart(Math.random());
								props.recognition.stop()
								props.recognition.start()
							} else {
								// if (recogStart) {
								// 	resultHandle();
								// 	setTimeout(() => {

								// 	}, SPEECHEND_TIME)
								// }
								// else {
									resultHandle()
								// }
							}
						}}
					/>
				</div>
			) : null}
		</section>
		</>
    )
}
export default SpeechView;